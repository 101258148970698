html {
  font-size: 16px;
  line-height: 1.313em;
  -webkit-font-smoothing: antialiased;
}
body {
  font-size: inherit;
  line-height: inherit;
  padding-top: 84px;
}



a {
  transition: color $global-transition-speed;
}



// Headings
.section-heading {
  font-size: .813em;
  text-transform: uppercase;
  font-weight: bold;
  color: lighten($text-color, 20%);
  border-bottom: 1px solid lighten($text-color, 25%);
  padding: 1em 0 2em 0;
  margin-bottom: 4em;
}




h2, h3 {
  line-height: 1.3;
}

p {
  line-height: 1.6  ;
  font-weight: 200;
  margin-bottom: 1.5em;
  color: $text-color;
}

.content, .rich-text {
  ul, ol {
    list-style: none;
    margin: 0;
    padding: 0 0 1.8em 15px;

    li {
      font-weight: 200;
      margin-bottom: 1.1em;
      position: relative;
      padding-left: 0.6em;
      font-size: 1em;
      line-height: 1.4em;

      &:before {
        font-family: 'FontAwesome';
        content: '\f10c';
        margin: 0 10px 0 -15px;
        color: $brand-secondary;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
        font-size: 0.8em;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ol {

    counter-reset: li;

    li {
      &:before {
        content: counter(li) ". ";
        counter-increment: li;
        font-family: inherit;
        font-weight: bold;
        font-size: 1.1em;

      }
    }
  }
}

// Content

.content {

  h2 {
    font-size: 2em;
    font-weight: 600;
    margin: 0 0 0.7em 0;
  }
  h3 {
    font-size: 1.4em;
    font-weight: 600;
    margin: 0 0 0.7em 0;
  }

  h4 {
    font-size: 1.2em;
    font-weight: 600;
    margin: 0 0 0.7em 0;
  }

  h2 + h3 {
    margin-top: -.8em;
  }

  p {
    font-weight: 300;
    margin-bottom: 1.8em;
    font-size: 1.1em;
    line-height: 1.4em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .rich-text {
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }

  }

  .col-md-6 {
    h3, h4 {
      margin-top: 0;
    }
  }

  .responsive-object {
    height: auto;

    twitterwidget {
      margin-left: auto;
      margin-right: auto;
    }

  }

}


// Section Title

.overall-title, .content .overall-title {
  @extend .section-heading;
  color: $gray;
  font-size: 13px;
  font-family: $font-family-alt;
  padding: 0 0 1em 0;
  margin: 0 0 1em 0;
}


// Icons
.big-icon {
  font-size: 9em;
  opacity: .5;
  margin: .1em 0 .2em 0;
}



.pre-title {
    display: inline-block;
    font-family: $font-family-alt;
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    font-size: .813em;
    padding: 1.5em 0 1em 0;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      height: 3px;
      width: 5.5em;
      background-color: $brand-primary;
      left: 0;
      bottom: 0;

      .ethernet & {
        background-color: #5A9245;
      }
      .mobile & {
        background-color: #983030;
      }
    }




  }

// Bread Crumb
.breadcrumb {
  background: transparent;
  border-bottom: 1px solid $gray-lightest;
  border-radius: 0;
  text-transform: uppercase;
  font-family: $font-family-alt;
  font-size: .9em;
  padding: 1.1em 0 1em 0;
  margin-bottom: 0;

  li {

    a {

    }

    &.active {
      color: $text-color;
    }

    &+li:before {
      content: '\f093';
      font-family: "Black Tie";
      font-size: 1em;
      position: relative;
      top: .15em;
      margin: 0 .6em;
    }

  }

}


// Tables
.table {

  border-spacing: 3px;
  border-collapse: separate;
  margin-bottom: 2em;

  * {
    border: 0 !important;
  }

  tbody tr {
    th, td {
    text-align: center;
    }
    th {
      background: #26275A;
      color: #fff;
      font-family: $font-family-alt;
      text-transform: uppercase;
      font-size: .75em;
      padding: 1.5em 1em;
    }
    td {
      background: $gray-lighter;
      font-size: .876em;
      padding: .7em .5em;
      font-weight: 200;
      vertical-align: middle;
    }
  }



}


// Well

.well {
  background-image: linear-gradient(-180deg, #F0F0F0 0%, #FFFFFF 100%);
  border: 1px solid #F5F5F5;
  border-radius: 10px;
  padding: 1.9em 1.2em;
  box-shadow: none;
  margin-bottom: 2em;

  p {
    line-height: 1.6em;
  }

  p:last-child {
    margin-bottom: 0;
    font-weight: 200;
  }
}

// Modals

.modal {
  .modal-dialog {

    top: 0;
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;

    .modal-header {
      background: white;
      padding: 10px 20px;

      h3 {
        small {
          font-weight: 300;
          font-family: $font-family-sans-serif;
          text-transform: uppercase;
          font-size: 0.5em;
          margin-left: 0.2em;
          color: $gray;
        }
      }
    }

    .modal-content {
      background: $gray-lighter;
      box-shadow: none;
      border-radius: 0;
      margin: 0 auto;

      .modal-body {
        font-size: 0.95em;
        padding: 20px;

        p {
          &:last-child {
            margin-bottom: 0;
          }
        }

        .modal-meta {
          margin-top: 1em;
          display: block;
          text-align: right;

          .btn {
            margin-right: 0.5em;
            text-transform: uppercase;
            font-size: 0.8em;
            padding: 0.6em 1.5em;

            &.btn-default {
              border: 1px solid $brand-blue;
              color: $brand-blue;
              background: transparent;
              border-radius: 50px;
            }

            &.btn-primary {
              border: 1px solid white;
              color: $brand-blue;
              background: white;
              border-radius: 50px;
            }
          }
        }
      }
    }
  }
}


// Video Embeds


.responsive-object {
  position: relative;
  padding-bottom: 67.5%;
  height: 0;
  margin: 10px 0;
  overflow: hidden;

  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


// richtext images

.rich-text {
  @extend .clearfix;
}

.richtext-image {

  max-width: 45%;

  &.right {
    float: right;
    margin: 0 0 1em 1em;
  }
  &.left {
    float: left;
    margin: 0 1em 1em 0;
  }

  &.full-width {
    @extend .img-responsive;
    @extend .center-block;
  }

}

// monospace - https://gitlab.dev.aa.net.uk/websites/aa-website/-/issues/84

.monospace {
    font-family: OCR-B, Monospace;
}