// Glyphicons font path
$icon-font-path:        "../fonts/";

// Colors
$brand-primary:             #1F1D3E;
$brand-blue:                #020C7E;
$brand-primary-light:       #686683;
$brand-secondary:           #BDE480;

.brand-gradient {
    background: $brand-primary;
    background-image: linear-gradient(-90deg, #26275A 0%, #111978 100%);
}

$global-transition-speed:   150ms;

$font-family-base:  'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-family-alt:   franklin-gothic-urw,mono,sans-serif,arial;

$text-color: #6E6E6E;

$gray: #6E6E6E;
$gray-light: #E9E9E9;
$gray-lighter: lighten($gray, 52%);
$gray-lightest: #e1e1e1;

$headings-color: $brand-primary;
$headings-font-weight: bold;

// Navbar Breakpoint

$grid-float-breakpoint: 1024px;

// Typekit Fixes
.wf-loading {
    visibility: hidden;
}

.wf-active {
    visibility: visible;
}

.wf-inactive {
    visibility: hidden;
}

