.btn {

  font-size: .875em;
  background: $gray;
  border: 0;
  border-radius: 0;
  transition: background $global-transition-speed;
  padding: .5em 1.2em;
  cursor: pointer;

  &.btn-default {
    background-color: $gray-light;
    color: $brand-primary;

    &:hover {
      background-color: darken($gray-light, 5%);
    }
  }

  &.btn-primary {
    @extend .brand-gradient;
    color: #fff;

    &:hover {
      background-color: lighten($brand-primary, 10%)
    }

    &.btn-outline {
      color: $brand-primary;

      &:hover {
        color: $brand-primary;
        background: transparent;
      }
    }
  }

  &.btn-large {

    border-radius: 3em;
    font-size: .813em;
    text-transform: uppercase;
    background-image: linear-gradient(-180deg, $brand-secondary 0%, darken($brand-secondary, 25%) 100%);
    color: #fff;
    font-weight: 700;
    padding: .8em 2em;
    text-shadow: 0 0 4px rgba(0,0,0,.2);
    transition: background $global-transition-speed;

    &:hover, &:focus, &:active {
      background-image: linear-gradient(-180deg, $brand-secondary 0%, darken($brand-secondary, 30%) 100%);
      color: white;
    }



    &.btn-inverse {

    }

  }

  &.btn-secondary {
    $thiscolor: darken($brand-secondary, 30%);
    color: $thiscolor;
    border-color: $thiscolor;
  }

  &.btn-outline {
    background: none;
    border-width: 1px;
    border-style: solid;
    text-shadow: none;
    font-weight: 200;

    &:hover, &:active, &:focus {
      background: transparent;
      color: $brand-secondary;
    }
  }


}


// Link block
.link-block-container {
  padding-top: 2em;
  padding-bottom: 2em;

  &.with-title {
    padding-top: 0;
  }
}


.link-block {
  display: block;
  color: #fff;
  text-decoration: none !important;
  text-align: center;
  background: no-repeat center center $gray;
  background-size: cover;
  height: 300px;
  position: relative;
  overflow: hidden;
  width: 100%;

  @media screen and (max-width: $screen-xs-max){
    height: 220px;
    font-size: .7em;
    margin-bottom: .5em;
  }
  @media screen and (max-width: $screen-sm-max){
    height: 220px;
    font-size: .7em;
  }


  img {
    height: 5em;
  }


  h2 {
    font-size: 2em;
    font-weight: bold;
    color: inherit
  }

  .main {
    padding-top: 4em;
    position: relative;
    z-index: 2;
    opacity: 1;
    transition: opacity 200ms 250ms;
    height: 100%;
    // Mask
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      z-index: 1;
      opacity: 0.8;
      background-image: linear-gradient(-180deg, rgba(43,43,43,0.73) 0%, rgba(19,19,19,0.34) 100%);
      z-index: -1;
    }
  }

  .hover {
    position: absolute;
    padding-top: 4em;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(39,39,90,.8);
    transition: top 250ms;
    z-index: 3;

    h2 {
      margin-bottom: 1em;
      color: inherit;
    }

  }

  &:hover {
    color: #fff;

    .main {
      opacity: 0;
      transition: opacity 150ms;
    }

    .hover {
      top: 0%;
      transition: top 250ms 200ms;
    }

  }
}


// Circles
.special-circle {
  height: 2.814em;
  width: 2.814em;
  font-size: 2.688em;
  overflow: hidden;
  display: inline-block;
  border-radius: 3em;
  background-image: linear-gradient(-180deg, #BDE480 0%, #88C549 100%);
  color: #fff;
  padding-top: 1em;
  margin-bottom: .5em;
  font-family: $font-family-alt;

  span {
    display: block;
  }

  .figure {

  }

  .name {
    font-size: .302em;
    text-transform: uppercase;
    margin: .5em 0;
  }

}

// Bar Link
.alt-strip {
  background: $gray-light;
  padding: 3em 0;
}

// User Selectable buttons
.btn-cta {
  @extend .btn;
  @extend .btn-default;
  color: #fff!important;
  background: $brand-primary;
  background-image: linear-gradient(-90deg, #26275A 0%, #111978 100%);
  font-size: .9rem;

    &:after {
      content: '\f0AB';
      font-family: "Black Tie";
      margin-left: .6em;
      position: relative;
      top: .1em;
    }
  @media screen and (max-width: $screen-xs-max) {
    margin-bottom: 2em;
  }

  &:hover {
    background: $brand-primary-light;
  }
}