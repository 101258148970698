// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}


.input-group-field {
    display: table-cell;
    vertical-align: middle;
    border-radius:4px;
}
.input-group-field .form-control, .input-group-field .form-control {
    border-radius: inherit !important;
}
.input-group-field:not(:first-child):not(:last-child) {
    border-radius:0;
}
.input-group-field:not(:first-child):not(:last-child) .form-control {
    border-left-width: 0;
    border-right-width: 0;
}
.input-group-field:last-child {
    border-top-left-radius:0;
    border-bottom-left-radius:0;
}
.plusvat, .incexc-dagger {
    color: #9c9c9c;
    font-size: x-small;
    vertical-align: super;
}

.intro-block a {
    color: black;
}

.intro-block a:hover {
    text-decoration: underline;
}
.content a, .rich-text a {
    text-decoration: underline;
}
.content a:hover, .rich-text a:hover {
    text-decoration: none;
}

a.btn {
    text-decoration: none;
}

.btn:hover {
    text-decoration: underline;
}
