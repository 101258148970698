.navbar {
  background: $gray-light;
  padding-top: 1em;
  padding-bottom: 1em;

  margin-bottom: 0;
  border-radius: 0;
  box-shadow: none;
  transition: padding-top 0.5s, padding-bottom 0.5s, box-shadow 1s;

  .container {
    position: relative;
  }

  .navbar-btn {
    margin-top: 1em;
    border-radius: 30px;
    font-family: $font-family-alt;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 0.813em;

    &.btn-default {
      background: white;
      border: 1px solid white;
      opacity: 1;
      transition: opacity 0.5s;
      color: $brand-blue;

      &:hover {
        opacity: 0.8;
      }
    }

    &.btn-primary {
      background: transparent;
      border: 1px solid $brand-blue;
      color: $brand-blue;
    }
  }

  .navbar-collapse {
    width: 100%;
  }

  .contact-btn {
    @media screen and (min-width: $screen-md) and (max-width: $screen-md-max){
      //font-size: .8em;
      float: right;
    }
  }

  .logo {
    display: block;

    img {
      width: 240px;
      margin-top: 0.05em;
      transition: width 0.5s, margin-top 0.5s, max-width 0.5s;

      @media (min-width: $screen-sm) {
        margin-top: 0.4em;
      }

      @media (min-width: $screen-md) {
        margin-top: 0.05em;
      }

      &.logo-small {
        max-width: 50px;
        margin-top: 0.2em;
      }
    }
  }

  .navbar-toggle {
    float: none;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0.2em;
  }

  .toggle-and-contact {
    position: absolute;
    right: 0;
    top: -0.08em;
    transition: top 0.5s;
  }

  &.sticky {
    padding-top: 0.15em;
    padding-bottom: 0.5em;
    box-shadow: 0 0 15px rgba(0,0,0,0.3);
    z-index: 5;

    @media (min-width: $screen-sm) {
      padding-bottom: 0.5em;
    }

    @media (min-width: $screen-md) {
      padding-bottom: 0.2em;
    }

    .logo {
      img {
        width: 190px;
        margin-top: 0.45em;

        &.logo-small {
          width: 40px;
          max-width: 40px;
          margin-top: 0.5em;
        }

        @media (min-width: $screen-sm) {
          margin-top: 0.4em;

          &.logo-small {
            margin-top: 0.45em;
          }
        }

        @media (min-width: $screen-md) {
          margin-top: 0.43em;
        }
      }
    }

    .toggle-and-contact {
      top: -0.1em;
    }
  }

  &.navbar-fixed-top {
    z-index: 5;
  }
}


.navbar-nav {

  text-align: right;

  @media screen and (min-width: $screen-sm) and (max-width: $screen-sm-max){
    margin-left: -15px;
  }

  >li {

    position: relative;
    margin-left: 0;
    border-bottom: 1px solid #ccc;

    @media (min-width: $screen-md) {
      margin-left: 0.3em;
      border-bottom: none;
    }

    @media screen and (min-width: $screen-md) and (max-width: $screen-md-max){
      &:first-child { margin-left: 0; }
    }

    >a {
      font-family: $font-family-alt;
      font-weight: 400;
      text-transform: uppercase;
      font-size: 0.813em;
      color: $text-color;
      background: none !important;
      text-align: center;
      padding-top: 1.48em;

      @media screen and (max-width: $screen-sm-max) {
        text-align: left;
        font-size: 0.9em;
      }



      &:hover, &:active, &:focus {
        background: none;
        color: $brand-primary;
      }
    }


    &:after {
      content:'/';
      font-weight: 300;
      position: absolute;
      left: 0.4em;
      top: 1.1em;

      @media screen and (max-width: $screen-sm-max) {
        content: none;
      }
    }

    &:hover {
      &:after {
        color: $brand-primary;
      }
    }
  }

  .dropdown-menu {

    li {
      a {
        color: $brand-primary;
      }
    }

    @media (min-width: $screen-md) {
    background: #FFFFFF;
    box-shadow: 0px 2px 15px 10px rgba(0,0,0,0.04);
    border-radius: 1em !important;
    transform: translate(-20%);
    padding: 0;


    li {
      a {
        font-size: 1em;
        color: $text-color;
        transition: color $global-transition-speed,  background $global-transition-speed;
        padding: .3em .2em .3em .8em;

        &:hover {
          color: darken($brand-secondary, 20%);
        }
      }

      &:first-child {
        a {
          border-radius: 1em 1em 0 0;
          padding-top: .6em;
        }
      }

      &:last-child {
        a {
          border-radius: 0 0 1em 1em;
          padding-bottom: .6em;
        }
      }
    }

      &:before {
        content:'';
        width: 0;
        height: 0;
        border: 10px solid #fff;
        border-color: transparent transparent #fff transparent;
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
  }

}

.navbar-toggle {
  z-index: 5;
  margin-right: 0;

  .icon-bar {
    background: $brand-primary;
  }

  &:hover, &:active, &:focus {
    background-color: transparent;

    .icon-bar {
      background: #ccc;
    }
  }

  &.collapsed {
    .icon-bar {
      background: $brand-primary;
    }
  }
}


.contact-nav {
  background: #fff;
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 10;
  box-shadow: 0 2px 15px 25px rgba(0,0,0,0.37);
  display: block;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  top: -50%;
  transition: top 500ms;

  .pre-title {
    color: #aaa;
  }

  &.open {
    top: 0;
    transition: top 500ms;
  }

  .pre-title {
    @extend .pre-title;
    border: 0;
    padding: 0 0 .5em 0;

    &:after {
      content: none;

    }
  }

  .btn-large {
    border-radius: 30px;
    font-family: $font-family-alt;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 0.813em;
    padding: 0.5em 1.5em;

    @media (min-width: $screen-md) {
      display: block;
      margin: 0 auto 0.8em auto;
      max-width: 70%;

      &.btn-default {
        margin-top: 0.5em;
      }
    }
  }

  table {
    font-size: .817em;

    tr {
      td {
        vertical-align: middle;
        padding-bottom: .4em;
      }
      td:first-child {
        color: $brand-secondary;
        text-transform: uppercase;
        font-weight: bold;
        padding-right: 2em;
      }
      td:last-child {
        color: $gray;

        a {
          font-size: 1.538em;
          padding-right: .5em;
        }
      }

    }
  }

  @media screen and (max-width: $screen-sm-max){
    top: -100%;

    .text-right {
      text-align: left !important;
      padding-top: 1em;
    }
    table {
      margin-bottom: 1em;
    }
  }

  @media screen and (max-width: $screen-xs-max){
    top: -100%;

    .text-right {
      text-align: left !important;
      padding-top: 0;
    }
  }


  @media screen and (min-width: $screen-md) and (max-width: $screen-md-max){
    top: -80%;

  }


}